import type { ControllerConstructor } from 'stimulus'

//-- Types --
export type ControllerModule = { default?: ControllerConstructor }
export type LazyImportedModules = Record<
  string,
  () => Promise<ControllerModule>
>

//-- Constants --
export const CONTROLLER_ATTRIBUTE = 'data-controller'
const CONTROLLER_FILENAME_REGEX =
  /^(?:.*?(?:controllers|components)\/|\.?\.\/)?(.+?)(?:(?:[_-]component)?[_-]controller\..+?)$/

//-- Given a file path, returns the controller name (identifier) --
// Example: "./controllers/foo_bar_controller.js" => "foo-bar"
// Example: "./controllers/booking/foo_bar_controller.js" => "booking--foo-bar"
export function controllerNameFromFilePath(filePath: string) {
  const name = (filePath.match(CONTROLLER_FILENAME_REGEX) || [])[1]
  if (name) return name.replace(/_/g, '-').replace(/\//g, '--')
  return
}

//-- Given a controller name (identifier), returns a string that can be used as a clsas name for that controller --
// Example 'builder--editor--node-tree' -> 'builder__editor__node_tree
export function controllerClassNameFromControllerName(controllerName: string) {
  return controllerName.replace(/-/g, '_')
}

//-- Given an element, returns array of controller names used by that element (i.e in the data-controller attribute) --
export function extractControllerNamesFrom(element: Element) {
  return (
    element
      .getAttribute(CONTROLLER_ATTRIBUTE)
      ?.split(/\s+/)
      ?.filter((content) => content.length) || []
  )
}

//-- Converts the hash/object returned by vite's import.meta.glob (which is a hash of filePath to modules/module importer) to a hash of controller name to modules --
export function modulesByControllerName(modules: LazyImportedModules) {
  const result = {} as LazyImportedModules
  Object.entries(modules).forEach(([filePath, mod]) => {
    const controllerName = controllerNameFromFilePath(filePath)
    if (controllerName && mod) result[controllerName] = mod
    else console.error(`Could not find controller name for ${filePath}`)
  })
  return result
}
